.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 1rem 2rem;
}

.navbar .logo {
  height: 130px;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.navbar li a {
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.menu-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    background-color: rgb(213, 230, 255);
    justify-content: center;
    align-items: center;
    max-height: 0; /* Começa com altura 0 */
    overflow: hidden; /* Oculta o conteúdo quando max-height é 0 */
    transition: max-height 0.3s ease-in-out; /* Anima a mudança de altura */
  }

  .navbar ul.open {
    max-height: 500px; /* Altura máxima quando o menu está aberto */
  }

  .menu-icon {
    display: block;
  }

  .navbar ul li {
    margin: 1.5rem 0;
  }

  .navbar .logo {
    height: 80px;
  }
}



  
  