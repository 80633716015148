.contact {
  padding: 2rem;
  background-color: #004AAD;
  text-align: justify;
  border: none;
}

.contact h1 {
  font-size: 2rem;
  color: white;
  margin-bottom: 1.5rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.info-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: white;
}

.icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .contact {
    padding: 1rem;
  }

  .contact h1 {
    font-size: 1.5rem;
  }

  .info-item {
    font-size: 0.9rem;
  }

  .icon {
    font-size: 1rem;
  }
}

  