.home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .rectangle {
    width: 500px;
    height: 800px;
    background-color: #004AAD;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.3s, height 0.3s; 
  }
  
  .rectangle img {
    max-width: 900px;
    max-height: 600px;
  }
  
  @media (max-width: 768px) {
    .rectangle {
      max-width: 250px;
      min-height: 70vh;
    }
    .rectangle img {
      width: 360px;
    }
  }
  