body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #f1f9ff, #cee7ff);
}

#home, #sobre{
  padding: 1rem;
}

@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
    gap: 1rem;
  }

  .home .rectangle {
    width: 90%;
    height: auto;
  }

  .about {
    flex-direction: column;
  }

  .about-card {
    width: 90%;
    margin-right: 0;
  }

  .about-image {
    width: 90%;
    height: auto;
    margin-top: 1rem;
  }

  .contact {
    padding: 1rem;
  }

  .contact h1 {
    font-size: 1.5rem;
  }

  .info-item {
    font-size: 0.9rem;
  }

  .icon {
    font-size: 1rem;
  }
}
