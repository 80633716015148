.about {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: transparent;
}

.about-card {
  width: 600px;
  height: auto;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.about-card h2 {
  text-align: center;
  width: 100%;
  margin-bottom: 0rem;
  font-size: 30px;
}

.about-card p {
  font-size: 1.2rem;
  margin-bottom: 0rem;
  text-indent: 2rem;
}

.about-image {
  width: 600px;
  height: 400px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .about {
    flex-direction: column;
  }

  .about-card {
    width: 90%;
    margin-right: 0;
  }

  .about-image {
    width: 90%;
    height: auto;
    margin-top: 1rem;
  }
}


